* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  scroll-behavior: smooth;
}

/* Input types that require text input */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="number"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="color"],
textarea,
select {
  font-size: max(16px, 1rem); /* Minimum font size for iPhone */
  font-family: inherit; /* Inherits font family from the parent element */
  line-height: 1.2; /* Adjust line height for better readability */
  padding: 6px; /* Add some padding for a better look */
  border: 1px solid #ccc; /* Add border to make them look the same */
  border-radius: 4px; /* Add border-radius for a consistent appearance */
  box-sizing: border-box; /* Include padding and border in the total width and height */
  width: 100%; /* Make the input elements responsive */
  -webkit-appearance: none; /* Remove default appearance on iOS devices */
}

/* Focus state */
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="color"]:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default focus outline */
  border-color: #686868; /* Add custom focus border color */
  box-shadow: 0 2px 3px rgba(190, 190, 190, 0.6); /* Add a subtle box-shadow */
}

a,
a:visited,
a:active {
  color: #fff;
  text-decoration: none;
  transform: all 0.3s ease-in-out;
}

a:hover {
  color: #ff641c;
}

main {
  position: relative;
  min-height: 100vh;

  nav {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background: #242424;
    color: #fff;
    z-index: 100;

    div.nav__links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      color: #242424;
    }
  }

  .hero__container {
    min-height: 65vh;
    display: flex;
    align-items: flex-end;
    padding: 2rem;
    justify-content: center;

    .hero__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(2px);
      padding: 1rem;
      animation: fadeIn 1s ease-in-out forwards;
      box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.6);
      text-align: center;

      h1 {
        font-size: 4rem;
        color: #242424;
        max-width: 45rem;

        @media screen and (max-width: 768px) {
          font-size: 2.5rem;
        }
      }

      .subtitle {
        text-align: center;
        font-size: 1.5rem;
        color: #242424;
        justify-self: center;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }

      a {
        place-self: center;
      }
    }
  }

  div.content {
    padding: 2rem;
    position: relative;
    width: 40rem;
    max-width: 100%;
    margin: 3vh auto;
    animation: fadeIn 1s ease-in-out forwards;

    h2 {
      font-size: 2rem;
      text-transform: uppercase;
      text-align: center;
      color: #242424;
      max-width: 35rem;
      margin-bottom: 2rem;
      @media screen and (max-width: 768px) {
        font-size: 1.4rem;
      }
    }

    p {
      font-size: 1rem;
      color: #242424;
      margin-bottom: 2rem;
      letter-spacing: 0.5px;
      line-height: 1.5rem;
    }

    form {
      display: grid;
      gap: 2rem;
      label {
        font-size: 1rem;
        color: #242424;
        margin: 0.5rem 0 -0.7rem 0;
      }

      fieldset {
        border: none;
        border-left: 2px solid #ff641c;
        padding: 1rem;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
      }
    }
  }
}

.team__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.team__member {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  align-items: flex-start;
  padding: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .member__image {
    display: grid;
    text-align: center;
    gap: 1rem;

    img {
      position: relative;
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 50%;
      background: #ccc;
      margin: 0 auto;
    }
  }
  .member__details {
    padding: 0px 1rem;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  div {
    max-width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fadeIn {
  animation: animateIn 1s ease-in-out forwards !important;
}

.fadeOut {
  animation: animateOut 1s ease-in-out forwards !important;
}

@keyframes animateIn {
  0% {
    transform: translateX(80px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes animateOut {
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0.5;
    transform: translateX(-80px);
  }
}

div.registration {
  p.reg__alert {
    position: absolute;
    color: #c41d1d;
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    transform: translateY(-0.2rem);
  }
}
