footer {
  background: #333;
  color: white;
  display: flex;
  padding: 2rem;

  .footer__container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0px;
    @media screen and (max-width: 820px) {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }

  .footer__links {
    display: grid;
  }
}
